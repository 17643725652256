import * as React from "react"
import styled from "styled-components"
import moment from "moment"
import ReplyIcon from "@mui/icons-material/Reply"
import {
  ListItemText,
  ListItemAvatar,
  ListItem,
  List,
  Divider,
  Avatar,
  Button,
} from "@mui/material"

import ReplyBox from "./ReplyBox"

const CommentDetails = ({ activateReply, details }) => {
  return (
    <CommentHeader>
      <Details>
        <span className="name">{details.username}</span>&#8226;
        <span className="time">
          {moment(details.createdAt || new Date()).fromNow()}
        </span>
      </Details>
        <Button size="small" onClick={activateReply} startIcon={<ReplyIcon />}>
          Reply
        </Button>
    </CommentHeader>
  )
}

const CommentList = ({ comments, activateReply, activeComment }) => {
  if (!comments || !comments.length) return null

  const lastComment = idx => idx === comments.length - 1

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {comments.map(
        (
          {
            id,
            postId,
            parentId,
            isReply,
            isLeaf,
            content,
            photoURL,
            username = "anonymous",
            createdAt,
            replies=[],
          },
          idx
        ) => (
          <>
            <CommentWrapper
              key={id}
              sx={{ paddingLeft: isReply ? "40px" : isLeaf ? '80px' : '0px' }}
            >
              <Comment>
                <ListItemAvatar>
                  {photoURL && <Avatar alt={username} src={photoURL} />}
                  {!photoURL && <Avatar>{username[0].toUpperCase()}</Avatar>}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <CommentDetails
                      activateReply={() => activateReply(id)}
                      details={{ username, createdAt }}
                    />
                  }
                  secondary={content}
                />
              </Comment>
              {activeComment === id && (
                <ReplyBox
                  postId={postId}
                  commentId={isLeaf ? parentId : id}
                  activateReply={activateReply}
                  replyToComment={parentId ? false : true}
                />
              )}
            </CommentWrapper>

            <CommentList
              comments={replies.reverse()}
              activateReply={activateReply}
              activeComment={activeComment}
            />

            {!lastComment(idx) && !parentId && <Divider component="div" />}
          </>
        )
      )}
    </List>
  )
}

export default CommentList

const CommentWrapper = styled(ListItem)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  & button {
    visibility: hidden;
  }
  &:hover button {
    visibility: visible;
  }
`
const Comment = styled(ListItem)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Details = styled.div`
  & .name {
    margin-right: 5px;
  }
  & .time {
    margin-left: 5px;
    color: #a1a1a1;
  }
`
