import { getDoc, setDoc, doc, updateDoc } from "firebase/firestore"
import { db } from "./firebase"

export const getUser = async (uid) => {
  const docRef = doc(db, "users", `${uid}`)
  const docSnap = await getDoc(docRef)
  const userInfo = docSnap.data()
  
  return userInfo
}

export const createUser = async (uid, userInfo) => {
    const docRef = doc(db, "users", `${uid}`)
    const docSnap = await getDoc(docRef)
  
    if (!docSnap.exists()) {
      await setDoc(docRef, userInfo);
    }
  }

export const updateUserLikedPosts = async (uid, postId) => {
    const docRef = doc(db, "users", `${uid}`)
    const docSnap = await getDoc(docRef)
    const userInfo = docSnap.data()
    
    await updateDoc(docRef, {
        likedPosts: userInfo.likedPosts.concat([postId])
      });
  }
  