import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import CommentList from "../components/CommentList"
import CommentBox from "../components/CommentBox"
import PostStats from "../components/PostStats"
import Indent from "../components/Indentation"
import ImageGallery from "../components/ImageGallery"

import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

import "./blog.scss"
import useAuthContext from "../context/AuthContext"
import { createOrUpdatePost } from "../services/posts"
import createCommentTree from "../helpers/commentTree"
import { Paper } from "@mui/material"
import { Typography } from "@mui/material"

const shortcodes = { Indent }

export default function BlogPost({ data }) {
  const post = data.mdx
  const [activeComment, setActiveComment] = React.useState(null)

  const { postComments = [], getPostComments, postDetails = {}, getPostDetails } =
    useAuthContext()

  const activateReplyHandler = commentId => {
    setActiveComment(commentId)
  }

  const likePostHandler = async () => {
    const likedPosts = JSON.parse(localStorage.getItem('likedPosts'))
    if (likedPosts.includes(post.id)) return;

    await createOrUpdatePost({
      id: post.id,
      likeCount: (postDetails.likeCount || 0) + 1,
    })
    likedPosts.push(post.id)
    localStorage.setItem('likedPosts', JSON.stringify(likedPosts))

    getPostDetails(post.id)
  }

  React.useEffect(() => {
    getPostDetails(post.id)
    getPostComments(post.id)
  }, [])

  return (
    <Layout>
      <section className="blogpost">
        <h2 className="mb-2">{post.frontmatter.title}</h2>
        {post.frontmatter.tags.map(tagFromPost => (
          <Link
            className="tag"
            to="/blog"
            state={{ tagFromPost }}
            key={Math.random().toString()}
          >
            {tagFromPost}
          </Link>
        ))}
        <div className="post-date-like">
          <div className="flex justify-between w-full">
            <p className="mt-2 mb-1 italic">{post.frontmatter.date}</p>
            <Link to="/evolutionary-astrology/abbreviations" className="mt-2 mb-1">Abbreviations</Link>
          </div>
        </div>
        <hr></hr>
        {/* <div dangerouslySetInnerHTML={{ __html: post.html }} /> */}
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {post.body}
          </MDXRenderer>
        </MDXProvider>
      </section>
      <div>
        <PostStats
          totalComments={postComments.length}
          totalLike={postDetails.likeCount || 0}
          likePost={likePostHandler}
        />
        <CommentBox postId={post.id} />
        <div>
          <h2>Comments</h2>
          <Paper>
            <CommentList
              comments={createCommentTree(postComments)}
              activateReply={activateReplyHandler}
              activeComment={activeComment}
            />
          </Paper>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query BlogQuery($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        tags
      }
      id
    }
  }
`
