import React, { useState, useEffect } from "react"
import styled from "styled-components"
import FacebookIcon from "@mui/icons-material/Facebook"
import TwitterIcon from "@mui/icons-material/Twitter"
import GoogleIcon from "@mui/icons-material/Google"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import LogoutIcon from '@mui/icons-material/Logout';

import {
  TextField,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material"

import useAuthContext from "../context/AuthContext"
import { addComment } from "../services/comments"
import {
  signInWithFacebook,
  signInWithTwitter,
  signInAsAnonymous,
  signInWithGoogle,
  logout,
} from "../services/auth"

export default function CommentBox({ postId }) {
  const { user, getPostComments } = useAuthContext()
  const [comment, setComment] = useState("")

  const submitCommentHandler = async () => {
    if (!comment) return
    const commentDetails = {
      content: comment,
      userId: user.uid,
      username: user.displayName || "Anonymous",
      photoURL: user.photoURL,
      postId,
      parentId: null,
      isReply: false,
      isLeaf: false,
      createdAt: new Date().getTime(),
    }
    await addComment(commentDetails)
    setComment("")
    getPostComments(postId)
  }

  useEffect(() => getPostComments(), [])

  return (
    <Wrapper>
      <h1>Leave a comment</h1>
      <TextField
        placeholder="Write a comment"
        value={comment}
        onChange={ev => setComment(ev.target.value)}
        rows={4}
        multiline
        fullWidth
      />
      <ButtonWrapper>
          {!user && (
        <LoginButtons>
            <>
              <Tooltip title="Login with Facebook">
                <IconButton aria-label="facebook" onClick={signInWithFacebook}>
                  <FacebookIcon color="primary" fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Login with Twitter">
                <IconButton aria-label="twitter" onClick={signInWithTwitter}>
                  <TwitterIcon color="info" fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Login with Google">
                <IconButton aria-label="google" onClick={signInWithGoogle}>
                  <GoogleIcon color="error" fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Login as Anonymous">
                <IconButton aria-label="anonymous" onClick={signInAsAnonymous}>
                  <AccountCircleIcon color="default" fontSize="large" />
                </IconButton>
              </Tooltip>
            </>
        </LoginButtons>
        )}
        {user && <div>
          {user && user.isAnonymous && <span className="loggedin-user">Logged in as Anonymous</span>}
          {user && !user.isAnonymous && <span className="loggedin-user">Logged in as <b>{user.displayName}</b></span>}
          <IconButton aria-label="logout" onClick={logout}><LogoutIcon color="error" /></IconButton>
        </div>}
        <Button
          variant="outlined"
          color="secondary"
          onClick={submitCommentHandler}
          disabled={!user || !comment}
        >
          Post
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 22px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 8px 0px;
  width: 100%;
`

const LoginButtons = styled.div``
