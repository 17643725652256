import React from "react"
import styled from "styled-components"
import { Paper, IconButton } from "@mui/material"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import ChatIcon from "@mui/icons-material/Chat"

export default function PostStats({ totalLike, totalComments, likePost, disableLike }) {
  return (
    <Wrapper>
      <StatItem>
        <span className="count">{totalLike} likes</span>
        <IconButton onClick={likePost} disabled={disableLike}>
          <ThumbUpIcon color="primary" />
        </IconButton>
      </StatItem>
      <StatItem>
        <span className="count">{totalComments} comments</span>
        <ChatIcon color="primary" />
      </StatItem>
    </Wrapper>
  )
}

const Wrapper = styled(Paper)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
`

const StatItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 12px;
  & .count {
    margin-right: 5px;
  }
`
