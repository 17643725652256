const createCommentTree = comments => {
  const topComments = {}
  const replies = {}
  const leaves = []

  comments.forEach(c => {
    if(!c.parentId) {
      topComments[c.id] = { ...c, replies: [] }
    } else if (c.isReply) {
      replies[c.id] = { ...c, replies: [] }
    } else if(c.isLeaf) {
      leaves.push(c)
    }
  })

  leaves.forEach(leaf => {
    replies[leaf.parentId].replies.push(leaf)
  })

  Object.values(replies).forEach(reply => {
    topComments[reply.parentId].replies.push(reply)    
  })

  const tree = Object.values(topComments)
  return tree
}

export default createCommentTree
