import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { TextField, Button } from "@mui/material"

import useAuthContext from "../context/AuthContext"
import { addComment } from "../services/comments"

export default function ReplyBox({ postId, commentId, replyToComment, activateReply }) {
  const { user, getPostComments } = useAuthContext()
  const [comment, setComment] = useState("")

  const submitCommentHandler = async () => {
    if (!comment) return
    const commentDetails = {
      content: comment,
      userId: user.uid,
      username: user.displayName || "Anonymous",
      photoURL: user.photoURL,
      postId,
      isReply: replyToComment ? true : false,
      isLeaf: replyToComment ? false : true,
      parentId: commentId,
      createdAt: new Date().getTime(),
    }

    await addComment(commentDetails)
    setComment("")
    activateReply(null)
    getPostComments(postId)
  }

  return (
    <Wrapper>
      <TextField
        placeholder="Reply to comment..."
        value={comment}
        size="small"
        onChange={ev => setComment(ev.target.value)}
        rows={1}
        multiline
        fullWidth
      />
      <Button
        variant="outlined"
        color="secondary"
        onClick={submitCommentHandler}
        disabled={!user || !comment}
      >
        Reply
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12px;
  & button {
    margin-left: 5px;
  }
`
