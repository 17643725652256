import React from 'react'
import styled from 'styled-components'

const ImageGallery = ({props, children}) => (
    <StyledDiv className={`grid grid-rows-${props.rows} grid-cols-${props.cols}`}>
        {children} 
        
    </StyledDiv>
)

const StyledDiv = styled.div`
    display: grid;  
`


export default ImageGallery