import {
  signInWithPopup,
  FacebookAuthProvider,
  TwitterAuthProvider,
  GoogleAuthProvider,
  signInAnonymously,
  signOut
} from "firebase/auth"
import { auth } from "./firebase"
import { createUser } from "./users"

const fbProvider = new FacebookAuthProvider()
const twProvider = new TwitterAuthProvider()
const goProvider = new GoogleAuthProvider()

export const signInAsAnonymous = async () => {
  try {
    const user = await signInAnonymously(auth)
    createUser(user.uid, {signInMethod: 'Anonymous', username: 'Anonymous', likedPosts: []})
  } catch (error) {
    console.log(error.message)
  }
}

export const signInWithFacebook = () => {
  signInWithPopup(auth, fbProvider)
    .then(result => {
      const user = result.user
      const { signInMethod } = FacebookAuthProvider.credentialFromResult(result)
      
      createUser(user.uid, {signInMethod, username: user.displayName, likedPosts: []})
      
    })
    .catch((error) => {
      console.log("error => ", error)
      console.log('Error logging in with Facebook')
    })
}

export const signInWithTwitter = () => {
  signInWithPopup(auth, twProvider)
    .then(result => {
      const user = result.user
      const { signInMethod } = TwitterAuthProvider.credentialFromResult(result)
      
      createUser(user.uid, {signInMethod, username: user.displayName, likedPosts: []})
    })
    .catch(() => {
      console.log("Error logging in with Twitter")
    })
}

export const signInWithGoogle = () => {
  signInWithPopup(auth, goProvider)
    .then(result => {
      const user = result.user
      const { signInMethod } = GoogleAuthProvider.credentialFromResult(result)
      
      createUser(user.uid, {signInMethod, username: user.displayName, likedPosts: []})
    })
    .catch(() => {
      console.log("Error logging in with Google")
    })
}

export const logout = () => signOut(auth)